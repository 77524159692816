exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dedykowane-aplikacje-i-systemy-js": () => import("./../../../src/pages/dedykowane-aplikacje-i-systemy.js" /* webpackChunkName: "component---src-pages-dedykowane-aplikacje-i-systemy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-makadu-js": () => import("./../../../src/pages/o-makadu.js" /* webpackChunkName: "component---src-pages-o-makadu-js" */),
  "component---src-pages-oferty-pracy-js": () => import("./../../../src/pages/oferty-pracy.js" /* webpackChunkName: "component---src-pages-oferty-pracy-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-projektowanie-graficzne-js": () => import("./../../../src/pages/projektowanie-graficzne.js" /* webpackChunkName: "component---src-pages-projektowanie-graficzne-js" */),
  "component---src-pages-projekty-js": () => import("./../../../src/pages/projekty.js" /* webpackChunkName: "component---src-pages-projekty-js" */),
  "component---src-pages-sklepy-internetowe-js": () => import("./../../../src/pages/sklepy-internetowe.js" /* webpackChunkName: "component---src-pages-sklepy-internetowe-js" */),
  "component---src-pages-strony-internetowe-js": () => import("./../../../src/pages/strony-internetowe.js" /* webpackChunkName: "component---src-pages-strony-internetowe-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-offer-js": () => import("./../../../src/templates/offer.js" /* webpackChunkName: "component---src-templates-offer-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

